import type { Icon } from '@phosphor-icons/react'
import { CheckCircle, Question, WarningCircle, XCircle } from '@phosphor-icons/react/dist/ssr'
import Link from 'next/link'
import type { ComponentProps, ReactNode, RefObject } from 'react'
import { AlertDescription, AlertTitle, BaseAlert } from './ui/BaseAlert'
import { Button } from './ui/Button'

type Variant = NonNullable<ComponentProps<typeof BaseAlert>['variant']>

const ICON_COMPONENTS: Record<Variant, Icon> = {
  info: Question,
  warning: WarningCircle,
  destructive: XCircle,
  success: CheckCircle,
}

export default function Alert({
  variant,
  title,
  description,
  className,
  buttons,
  ref,
}: {
  variant: Variant
  title: ReactNode
  description?: ReactNode
  className?: string
  buttons?: ReactNode | ReactNode[]
  ref?: RefObject<HTMLDivElement>
}) {
  const IconComponent = ICON_COMPONENTS[variant]
  return (
    <BaseAlert ref={ref} variant={variant} className={className}>
      <AlertTitle>
        <IconComponent weight="fill" className={'h-6 w-6 inline-block mr-1'} />
        {title}
      </AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
      {buttons && <div className="flex mt-4 flex-col gap-2 items-end">{buttons}</div>}
    </BaseAlert>
  )
}

export function SendFeedbackAlertButton() {
  return (
    <Button variant="secondary" size="small" asChild>
      <Link target="_blank" href="https://sensay.canny.io/bugs">
        Send feedback
      </Link>
    </Button>
  )
}

export function DismissAlertButton({
  onDismiss,
}: {
  onDismiss: () => void
}) {
  return (
    <Button onClick={onDismiss} variant={'secondary'} size={'small'}>
      Dismiss
    </Button>
  )
}
